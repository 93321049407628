import React from 'react'
import { Alert, Button, Layout } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WhatsAppOutlined } from '@ant-design/icons'
import _ from 'lodash'
import dayjs from 'dayjs'
import classNames from 'classnames'
import NProgress from 'nprogress'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import AccountTransactionModal from 'containers/Reports/AccountTransactions/AccountTransactionModal'
import Onboarding from 'containers/OnboardingChecklist'
import BroadcastModal from 'containers/Broadcast'
import NPS from 'containers/NPS'
import { Welcome } from 'containers/ProductTour'
import LoginModal from 'pages/user/login/LoginModal'
import { inits } from 'redux/actions'
import { showBroadcast } from 'redux/broadcast/actions'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { resetDashboard } from 'redux/finance/actions'
import OneSignalApp from 'utils/oneSignal'
import Scrollbars from 'react-custom-scrollbars-2'
import AuditByTransType from 'containers/Audit/AuditByTranstype'
import LivestreamThumbnail from 'components/apps/livestream/LivestreamThumbnail'
import LivestreamAlert from 'components/apps/livestream/LivestreamAlert'
import Banner from 'components/apps/Banner'
import styles from './style.module.scss'

const mapStateToProps = ({
  settings,
  onboardingChecklist: { onboarding_checklist },
  _inits: {
    user: { id, name, role_id, email },
    livestream,
    show_nps: showNps,
    announcement,
    banner,
  },
  inits: { loading, loadedInit, attemp },
  options: { options },
  billing,
  broadcast: { data: broadcast, show: isShowBroadCast },
  translation,
  maintenance,
  loginForm: { showLoginForm },
  finance: {
    auditModal: { show: isShowAudit },
    accountTransactionReport: { show: isShowAccountTransaction },
  },
}) => ({
  onboardingChecklist: onboarding_checklist,
  darkMode: options.dark_mode,
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isMobileView: settings.isMobileView,
  id,
  name,
  role_id,
  loading,
  company: options.property_name,
  chatSupport: options.chat_support,
  chatNumber: options.chat_number,
  expiryDate: billing.expiry_date,
  dismissOnboardingChecklist: options.dismiss_onboarding_checklist,
  isProductTour: !options.onboarding && options.product_tour,
  broadcast,
  loadedInit,
  attemp,
  translation,
  announcement,
  maintenance,
  email,
  showLoginForm,
  livestream,
  showNps,
  isShowBroadCast,
  isShowAudit,
  isShowAccountTransaction,
  isShowWebinar: options.notification_webinar === 1,
  banner,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onInits: () => dispatch(inits()),
    onShowBroadcast: () => dispatch(showBroadcast()),
    push: (path) => dispatch(push(path)),
    onChangeCompany: (payload, callback) => {
      dispatch(resetDashboard())
      dispatch(setSelectedEndpoint(payload))
      if (typeof callback === 'function') callback()
    },
  }
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainLayout extends React.PureComponent {
  scrollBarRef = React.createRef()

  state = {
    initializeOneSignal: false,
  }

  componentDidMount() {
    if (
      !this.props.id &&
      !this.props.loading &&
      this.props.attemp <= 4 &&
      _.isEmpty(this.props.maintenance)
    ) {
      this.props.onInits()
    }
  }

  componentDidUpdate(prevProps) {
    const { loadedInit, broadcast, onShowBroadcast, id } = this.props
    if (prevProps.loadedInit !== loadedInit && loadedInit && !_.isEmpty(broadcast)) {
      onShowBroadcast()
    }
    if (prevProps.loadedInit !== loadedInit && loadedInit && id) {
      if (process.env.REACT_APP_ONESIGNAL_APP_ID && !this.state.initializeOneSignal) {
        OneSignalApp.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          userId: id,
          onOpenNotification: (data) => console.log(data),
          initialized: this.state.initializeOneSignal,
          setInizialized: () => this.setState({ initializeOneSignal: true }),
        })
      }
    }
  }

  isDashboard = () => {
    return this.props.location.pathname === '/dashboard'
  }

  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      loading,
      chatSupport,
      chatNumber,
      expiryDate,
      isMobileView,
      intl,
      name,
      company,
      isProductTour,
      companies,
      onChangeCompany,
      role_id,
      translation,
      announcement,
      dismissOnboardingChecklist,
      onboardingChecklist,
      darkMode,
      showLoginForm,
      showNPS,
      isShowBroadCast,
      isShowAudit,
      isShowAccountTransaction,
      isShowWebinar,
      livestream,
      banner,
    } = this.props

    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }

    let styleCustom = {}
    if (this.isDashboard()) {
      styleCustom = {
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        },
      }
    }

    return (
      <>
        <Layout
          className={classNames({
            settings__borderLess: isBorderless,
            settings__squaredBorders: isSquaredBorders,
            settings__fixedWidth: isFixedWidth,
            settings__menuShadow: isMenuShadow,
            settings__menuTop: isMenuTop,
          })}
        >
          <Menu scrollBarRef={this.scrollBarRef} />
          <Scrollbars style={{ height: '100vh' }} autoHide>
            <Layout style={{ position: 'relative' }} id="scrollable-container">
              {livestream?.is_live === '1' && !livestream?.is_show_thumbnail && !isMobileView && (
                <LivestreamAlert />
              )}
              {!_.isEmpty(announcement) && (
                <Alert
                  style={{ backgroundColor: '#ffefb3' }}
                  message={announcement}
                  type="warning"
                  showIcon
                  closable
                />
              )}
              {expiryDate && dayjs().subtract(1, 'days').isAfter(expiryDate) && (
                <Alert
                  style={{ backgroundColor: '#ffefb3' }}
                  message={
                    <FormattedMessage
                      id="billing.expired"
                      values={{
                        setup: (
                          <Link to="/settings/billing/upgrade">
                            <strong>
                              <FormattedMessage id="billing.expired_link" />
                            </strong>
                          </Link>
                        ),
                      }}
                    />
                  }
                  type="warning"
                  showIcon
                />
              )}

              <Layout.Header>
                <TopBar
                  intl={intl}
                  push={push}
                  role_id={role_id}
                  companies={companies}
                  translation={translation}
                  onChangeCompany={onChangeCompany}
                  isMobileView={isMobileView}
                  darkMode={darkMode}
                />
              </Layout.Header>
              <Layout.Content>
                <Breadcrumbs />
                <div className="utils__content" {...styleCustom}>
                  {children}
                </div>
              </Layout.Content>
              <Layout.Footer>
                <Footer />
              </Layout.Footer>
            </Layout>
          </Scrollbars>
        </Layout>
        {livestream?.is_live === '1' && livestream?.is_show_thumbnail && (
          <LivestreamThumbnail isChatSupportShowed={chatSupport === 1} />
        )}
        {chatSupport === 1 && (
          <Button
            href={`https://api.whatsapp.com/send?phone=${chatNumber}&text=Hallo,%20saya%20${name}%20dari%20${company}%20ingin%20tanya%20tentang%20Kledo.`}
            target="_blank"
            rel="noopener noreferrer"
            type="primary"
            shape={isMobileView ? 'circle' : 'round'}
            icon={<WhatsAppOutlined />}
            className={styles.waBtn}
          >
            {isMobileView ? null : intl.formatMessage({ id: 'button.hello_can_i_help' })}
          </Button>
        )}
        {onboardingChecklist === 1 && dismissOnboardingChecklist !== 1 && <Onboarding />}
        {(showNPS === 0 || showNPS === null) && <NPS />}
        {isShowAccountTransaction && <AccountTransactionModal />}
        {isShowAudit && <AuditByTransType />}
        {isShowBroadCast && <BroadcastModal />}
        {isProductTour && <Welcome scrollBarRef={this.scrollBarRef} />}
        {showLoginForm && <LoginModal />}
        {isShowWebinar && !_.isEmpty(banner) && <Banner banner={banner} />}
      </>
    )
  }
}

export default injectIntl(MainLayout)
